import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import ScrollContainer from 'react-indiana-drag-scroll'

const Table = ({ children, className }) => {
  return (
    <Wrapper className={className}>
      <ScrollContainer  className="scroll-container" style={{overflow : "auto"}} >
        <StyledTable className={className}>{children}</StyledTable>
      </ScrollContainer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  /* width: 100%; */
  overflow-x: auto;
  scrollbar-width: none;
`

const StyledTable = styled.table`
  font-size: 0.75rem;
  thead {
    background: ${DesignTokens.colors.primary[500]};
    color: white;
    line-height: 1.2;
  }

  th {
    position: sticky;
    top: 0;
    z-index: 2;
    background: ${DesignTokens.colors.primary[500]};
  }

  th,
  td {
    padding: 0.75rem 0.5rem;
  }
  td {
    white-space: nowrap;
  }
  tr:nth-child(even) {
    background: ${DesignTokens.colors.primary[100]};
  }
  &.no-overflow {
    td {
      white-space: inherit;
    }
  }
  img {
    min-width: 24px;
    max-width: 24px;
  }
`

export default Table
