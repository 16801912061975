import React from "react"
import styled from "styled-components"
import parse, { domToReact } from "html-react-parser"
import customBorder from "../images/custom-border.svg"
import DesignTokens from "./designTokens"
import {
  Body,
  Caption,
  Lead,
  HeadingSmall,
  Display,
  DisplaySmall,
  Blockquote,
} from "./typography"
import Table from "./table"

const RichContentTextBlock = ({ html, color, page }) => {
  const options = {
    replace: ({ name, children, attribs }) => {
      switch (name) {
        case "p":
          return <Body>{domToReact(children)}</Body>
        case "h1":
          return <Display as="h1">{domToReact(children)}</Display>
        case "h2":
          return <DisplaySmall as="h2">{domToReact(children)}</DisplaySmall>
        case "h3":
          return <HeadingSmall as="h3">{domToReact(children)}</HeadingSmall>
        case "h4":
          return <Lead as="h4">{domToReact(children)}</Lead>
        case "h5":
          return <Caption as="h5">{domToReact(children)}</Caption>
        case "h6":
          return <Caption as="h6">{domToReact(children)}</Caption>
        case "blockquote":
          return (
            <Blockquote color={page ? DesignTokens.colors.primary[500] : null}>
              {domToReact(children)}
            </Blockquote>
          )
        case "em":
          return <Red>{domToReact(children)}</Red>
        case "table":
          return (
            <Table className={attribs.class ? attribs.class : null}>
              {domToReact(children)}
            </Table>
          )
      }
    },
  }
  return <Wrapper color={color}>{parse(html, options)}</Wrapper>
}

const Wrapper = styled.div`
  color: ${props => props.color || "inherit"};
  width: 100%;
  h3 {
    margin-bottom: 2rem;
  }
  h2 {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding-top: 2rem;
    background-image: url(${customBorder});
    background-repeat: no-repeat;
  }
  & > * {
    margin-bottom: ${DesignTokens.spacing[3]};
    &:last-child {
      margin-bottom: 0;
    }
  }
  ul.special-list {
    list-style: none;
    & > li::before {
      content: "→";
      color: red;
      font-weight: bold;
      display: inline-block;
      width: 1rem;
      margin-left: -1rem;
      margin-right: 1rem;
    }
  }
  img {
    width: 100%;
  }
`

const Red = styled.em`
  color: #e20714;
`

export default RichContentTextBlock
